body {
  background-color: #e8eaf6;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23e1e4f3' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%23daddf1' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23d3d7ee' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23ccd0ec' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23C5CAE9' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  .xs-hidden {
    display: none;
  }
}
